import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

import * as moment from "moment"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Pagination from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Pagination"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"

const NewsOverviewPage = ({
  full_slug,
  name,
  content,
  news,
  language,
  numPages,
  currentPage,
  breadcrumbs,
}) => {
  let seoImage = null

  // if (!content.image?.filename && content.body && content.body[0] && content.body[0].image && content.body[0].image.filename) {
  //   seoImage = content.body[0].image.filename
  // } else {
  //   seoImage = content.image?.filename
  // }

  // let preparedNews = news.map((n) => { return Object.assign({}, n, { content: JSON.parse(n.content) }) })
  let preparedNews = news
  preparedNews = preparedNews.map((n) => {
    if (!n.content_image) {
      n.content_image = process.env.GATSBY_IMG_PLACEHOLDER
    }
    // if(!n.content.image && !n.content.image?.filename) {
    //   if(n.content.body && n.content.body[0] && n.content.body[0].image && n.content.body[0].image.filename) {
    //     n.content.image = n.content.body[0].image
    //   } else {
    //     n.content.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
    //   }
    // }
    return n
  })

  let pagePath = full_slug

  if (pagePath[pagePath.length - 1] === "/") {
    pagePath = pagePath.slice(0, pagePath.length - 1)
  }

  const nextPage = `${pagePath}/${String(currentPage + 1)}`
  const prevPage =
    currentPage - 1 === 1
      ? `${pagePath}`
      : `${pagePath}/${String(currentPage - 1)}`

  const bodySplitIndex =
    (content.body &&
      content.body.findIndex(
        (block) => block.component === "news_listing_placeholder_block"
      )) ||
    null

  let topBody = []
  let bottomBody = content.body

  if (bodySplitIndex) {
    topBody = content.body.slice(0, bodySplitIndex)
    bottomBody = content.body.slice(bodySplitIndex + 1)
  }

  const image = (item) => {
    if (item.content_image) {
      return item.content_image
    } else {
      if (item.content) {
        let c = JSON.parse(item.content)
        return c.image
      }
    }
  }

  const title = (item) => {
    if (item.content_title) {
      return item.content_title
    } else {
      if (item.content) {
        let c = JSON.parse(item.content)
        return c.title
      }
    }
  }

  const teaser = (item) => {
    if (item.content_teaser) {
      return item.content_teaser
    } else {
      if (item.content) {
        let c = JSON.parse(item.content)
        return c.teaser
      }
    }
  }

  const display_date = (item) => {
    if (item.content_display_date) {
      return item.content_display_date
    } else {
      if (item.content) {
        let c = JSON.parse(item.content)
        return c.display_date
      }
    }
  }

  let paginatedHeadline = `${
    content?.headline?.length > 0 ? content.headline : name
  }${
    currentPage !== 1
      ? language === "de"
        ? ": Seite " + currentPage
        : ": Page " + currentPage
      : ""
  }`

  return (
    <Layout language={language}>
      <SEO title={paginatedHeadline} content={content} name={name} />
      {topBody && topBody.length > 0 ? (
        <>
          {topBody[0].component !== "hero_block" && (
            <Breadcrumbs
              textColor="text-gray-500"
              breadcrumbs={breadcrumbs}
            ></Breadcrumbs>
          )}
          <BodyBlocks body={topBody} breadcrumbs={breadcrumbs} />
        </>
      ) : (
        <Breadcrumbs
          textColor="text-gray-500"
          breadcrumbs={breadcrumbs}
        ></Breadcrumbs>
      )}
      <BlockWrapper block={{}} blockPadding="pb-block pt-12">
        <h1 className="mb-8 headline headline-h1">
          {content?.headline?.length > 0 ? content.headline : name}
        </h1>
        <div className="flex flex-row flex-wrap -mx-grid">
          {preparedNews.length > 0 &&
            preparedNews.map((item) => (
              <div className="w-full px-grid md:w-1/2 lg:w-1/4" key={item.id}>
                <Link
                  className="flex flex-col justify-between h-full pb-10"
                  link={
                    item.fields?.full_slug_prepared
                      ? item.fields.full_slug_prepared
                      : item.full_slug
                  }
                >
                  <div className="block w-full border border-gray-100 aspect-square">
                    {image(item) && (
                      <Image
                        className="object-contain w-full h-auto"
                        image={image(item)}
                        fitIn={true}
                        fixedSize="350x350"
                        imageWidth="350"
                      />
                    )}
                  </div>
                  <div className="flex-1 p-4 -mt-px border border-gray-100">
                    <div>
                      <div className="mb-2">
                        <span className="inline-block w-3 h-3 align-baseline bg-primary"></span>
                        <span className="ml-4 prose-sm">
                          {moment(display_date(item)).format("DD.MM.YYYY")}
                        </span>
                      </div>
                      <span className="font-bold text-primary">
                        {title(item)}
                      </span>
                      <Richtext className="mt-2 prose-sm" text={teaser(item)} />
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </BlockWrapper>
      <Pagination
        currentPage={currentPage}
        isFirstPage={currentPage === 1}
        isLastPage={currentPage === numPages}
        totalPages={numPages}
        pagePath={pagePath}
        nextPage={nextPage}
        prevPage={prevPage}
      />
      {bottomBody && bottomBody.length > 0 && <BodyBlocks body={bottomBody} />}
    </Layout>
  )
}

export default NewsOverviewPage
